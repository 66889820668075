import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ProductState {
  [key: string]: any;
}

const initialState: ProductState = {};

const producCarouseltSlice = createSlice({
  name: 'product-carousel',
  initialState,
  reducers: {
    addProducts: (state, action: PayloadAction<ProductState>) => {
      if (action?.payload?.data) state[action.payload.key] = action.payload.data;
    },
    removeProducts: (state, action: PayloadAction<ProductState>) => {
      if (action?.payload?.key) delete state[action.payload.key];
    },
  },
});

export const { addProducts, removeProducts } = producCarouseltSlice.actions;

export default producCarouseltSlice.reducer;
