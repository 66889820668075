import Script from 'next/script';

const ConsentScripts = () => {
  const dataDomainScript = process.env.NEXT_PUBLIC_DATA_DOMAIN_SCRIPT_ID;
  // TODO: remove console.log and comment on line 7.
  console.log('dataDomainScript', dataDomainScript);
  // if (!dataDomainScript) return <></>;
  const isDev = process.env.NEXT_PUBLIC_CUST_ENV !== 'production';
  const isTestEnable = process.env.NEXT_PUBLIC_ENABLE_ONETRUST_TEST === 'true';

  return (
    <>
      {isDev || isTestEnable ? (
        // Load test scripts
        <>
          <Script
            type="text/javascript"
            src={`https://cdn.cookielaw.org/consent/${dataDomainScript}-test/OtAutoBlock.js`}
            strategy="afterInteractive"
          />
          <Script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script={`${dataDomainScript}-test`}
            strategy="afterInteractive"
          />
        </>
      ) : (
        // Load production scripts
        <Script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script={dataDomainScript}
          strategy="afterInteractive"
        />
      )}
      <Script type="text/javascript" id="onetrust-test" strategy="afterInteractive">
        {`function OptanonWrapper() { }`}
      </Script>
    </>
  );
};

export default ConsentScripts;
