import React, { ButtonHTMLAttributes } from 'react';
import { usePageType } from 'helpers/hooks/usePageType';
import { AnchorElement, TAdditionalContext } from 'helpers/types';
import { MemoizedAutoIdFromAdditionalContext, ReplaceUrlWithHost } from 'helpers/utils/common';
import { buttonCss } from '../styles';

export type Color = 'white' | 'green';
export type ButtonType = 'primary' | 'secondary' | 'tertiary';
export type Size = 'sm' | 'lg';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: Color;
  'button-type': ButtonType;
  size?: Size;
  as?: 'button';
  additionalContext?: TAdditionalContext;
}

export interface IAnchor extends AnchorElement {
  color: Color;
  'button-type': ButtonType;
  size?: Size;
  as?: 'a';
  href: string;
  host?: string; // At this point of time. host is optional from breaking other components. TODO: remove this option when other component are fixed with host.
  disabled?: boolean;
  additionalContext?: TAdditionalContext;
}

export type IButtonProps = IAnchor | IButton;

export const CommonButton: React.FC<IButtonProps> = (props) => {
  const pageType = usePageType();
  const { color, size = 'lg', disabled } = props;
  const parsedHost = (props as IAnchor).host;
  const cssClasses = `${buttonCss(props['button-type'], size, color, disabled)} ${props.className}`.replace(
    /\s\s+/g,
    ' ',
  );
  return props.as === 'a' ? (
    <a
      {...props}
      href={props?.host ? (props?.href ? ReplaceUrlWithHost(props.href, props?.host) : '/') : props.href || '/'}
      className={`${cssClasses} ${disabled ? ' pointer-events-none' : ''}`}
      data-testid={(props.additionalContext && props.additionalContext.testId) || props['data-testid'] || null}
      data-automationid={MemoizedAutoIdFromAdditionalContext(props.additionalContext, pageType)}
      target={
        props.target ? props.target : parsedHost ? (!props.href?.includes?.(parsedHost) ? '_blank' : '_self') : '_self'
      }
      rel={parsedHost ? !props.href?.includes?.(parsedHost) && 'nofollow' : null}
    >
      {props.children}
    </a>
  ) : (
    <button
      {...props}
      className={cssClasses}
      data-testid={(props.additionalContext && props.additionalContext.testId) || props['data-testid'] || null}
      data-automationid={MemoizedAutoIdFromAdditionalContext(props.additionalContext, pageType)}
    >
      {props.children}
    </button>
  );
};
