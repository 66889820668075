const checkoxTheme = {
  defaultProps: {
    color: 'green',
    label: undefined,
    icon: undefined,
    ripple: false,
    className: '',
    disabled: false,
    containerProps: undefined,
    labelProps: undefined,
    iconProps: undefined,
  },
  valid: {
    colors: ['green'],
  },
  styles: {
    base: {
      root: {
        display: 'flex',
        flex: 'flex-1',
        alignItems: 'flex-start',
        gap: 'gap-2.5 lg:gap-3',
        width: 'min-w-[280px] w-full',
      },
      container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        p: 'p-0',
        borderRadius: 'rounded-none',
        shrink: 'shrink-0',
      },
      input: {
        width: 'w-6',
        height: 'h-6',
        borderWidth: 'border-0',
        bg: 'bg-transparent bg-[url("/icons/figma/checkbox-empty.svg")]',
        checked: {
          bg: 'checked:bg-[url("/icons/figma/checkbox-filled.svg")]',
        },
        focus: {
          ring: 'focus:ring-transparent',
          ringOffSet: 'focus:ring-offset-transparent',
          color: 'focus:text-transparent',
        },
        hover: {
          color: 'hover:text-transparent',
        },
        before: {
          content: "before:content['']",
          display: 'before:block',
          bg: 'before:bg-transparent',
          width: 'before:w-12',
          height: 'before:h-12',
          borderRadius: 'before:rounded-full',
          position: 'before:absolute',
          top: 'before:top-2/4',
          left: 'before:left-2/4',
          transform: 'before:-translate-y-2/4 before:-translate-x-2/4',
          opacity: 'before:opacity-0 hover:before:opacity-10',
          transition: 'before:transition-opacity',
        },
      },
      label: {
        flex: '[flex:_1_0_0]',
        color: 'text-gray-700',
        fontWeight: 'font-light',
        userSelect: 'select-none',
        cursor: 'cursor-pointer',
        mt: 'mt-px',
      },
      icon: {
        color: 'text-white',
        position: 'absolute',
        top: 'top-2/4',
        left: 'left-2/4',
        translate: '-translate-y-2/4 -translate-x-2/4',
        pointerEvents: 'pointer-events-none',
        opacity: 'opacity-0 peer-checked:opacity-0',
        transition: 'transition-opacity',
      },
      disabled: {
        opacity: 'opacity-50',
        pointerEvents: 'pointer-events-none',
      },
    },
    colors: {
      green: {
        background: 'checked:bg-transparent',
        border: 'checked:border-transparent',
        before: 'checked:before:bg-transparent',
      },
    },
  },
};

export default checkoxTheme;
