import tw from 'tailwind-styled-components';
import { DrawerPlacement } from '.';

type TLoaderProps = {
  $showbackdrop?: boolean;
  $drawerplacement?: DrawerPlacement;
};

export const LoaderWrapper = tw.div<TLoaderProps>`
    fixed
    w-full
    h-full
    flex
    items-center
    justify-center
    ${(props) => props.$showbackdrop && 'bg-opacity-80 bg-light backdrop-filter'}
    z-[99995]
    ${(props) =>
      props.$drawerplacement
        ? props.$drawerplacement === 'bottom'
          ? 'absolute'
          : 'absolute'
        : 'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'}
`;
