import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IHomeDispensary {
  loading?: boolean;
  data?: any;
  error?: string;
}

type TSignUpSource = 'website-join-now' | 'website-post-checkout' | 'website-primary-nav';

interface IAccount {
  openLoginDrawer: boolean;
  user: any;
  homeDispensary: IHomeDispensary;
  signUpSource: TSignUpSource;
  loyaltyUser: {
    balance: number;
    walletLink: string;
  };
}

const initialState: IAccount = {
  openLoginDrawer: false,
  user: null,
  homeDispensary: {
    loading: false,
    data: null,
    error: '',
  },
  signUpSource: 'website-join-now',
  loyaltyUser: {
    balance: 0,
    walletLink: '',
  },
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setOpenLoginDrawer: (state, action: PayloadAction<boolean>) => {
      state.openLoginDrawer = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUserHomeDispensary: (state, action: PayloadAction<IHomeDispensary>) => {
      state.homeDispensary = { ...state.homeDispensary, ...action.payload };
    },
    setSignUpSource: (state, action: PayloadAction<TSignUpSource>) => {
      state.signUpSource = action.payload;
    },
    setLoyaltyUser: (state, action: PayloadAction<any>) => {
      state.loyaltyUser = action.payload;
    },
  },
});

export const { setOpenLoginDrawer, setUser, setUserHomeDispensary, setSignUpSource, setLoyaltyUser } =
  accountSlice.actions;

export default accountSlice.reducer;
