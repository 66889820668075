import drawerTheme from 'components/atoms/drawers/drawer-theme';
import checkboxTheme from 'components/atoms/inputs/checkbox/checkbox-theme';
import radioButtonTheme from 'components/atoms/inputs/radio/radio-theme';
import selectTheme from 'components/atoms/inputs/select/select-theme';
import inputTheme from 'components/atoms/inputs/text-field/input-theme';
import toggleTheme from 'components/atoms/inputs/toggle/toggle-theme';

export const customTheme = {
  input: inputTheme,
  radio: radioButtonTheme,
  switch: toggleTheme,
  select: selectTheme,
  checkbox: checkboxTheme,
  drawer: drawerTheme,
};
