const radioButtonTheme = {
  defaultProps: {
    color: 'green',
    label: undefined,
    icon: undefined,
    ripple: true,
    className: '',
    disabled: false,
    containerProps: undefined,
    labelProps: undefined,
    iconProps: undefined,
  },
  valid: {
    colors: ['green'],
  },
  styles: {
    base: {
      root: {
        display: 'flex',
        alignItems: 'items-center',
        flexDirection: 'sm:flex-row-reverse',
        width: 'min-w-[350px] w-full',
        gap: 'gap-2.5',
      },
      container: {
        position: 'relative ml-auto',
        alignItems: 'items-center',
        cursor: 'cursor-pointer',
      },
      input: {
        peer: 'peer',
        position: 'relative',
        appearance: 'appearance-none',
        width: 'w-6',
        height: 'h-6',
        cursor: 'cursor-pointer',
        transition: 'transition-all',
        flexShrink: 'shrink-0',
        color: 'bg-transparent',
        focus: {
          ringOffSet: 'focus:ring-offset-transparent ',
          ring: 'focus:ring-transparent',
        },
        before: {
          content: "before:content['']",
          display: 'before:block',
          bg: 'before:bg-blue-gray-500',
          width: 'before:w-12',
          height: 'before:h-12',
          borderRadius: 'before:rounded-full',
          position: 'before:absolute',
          top: 'before:top-2/4',
          left: 'before:left-2/4',
          transform: 'before:-translate-y-2/4 before:-translate-x-2/4',
          opacity: 'before:opacity-0 hover:before:opacity-0',
          transition: 'before:transition-opacity',
        },
      },
      label: {
        fontFamily: 'font-arial',
        fontSize: 'text-sm lg:text-base',
        fontWeight: 'font-normal',
        fontStyle: 'not-italic',
        lineHeight: 'eading-4',
        letterSpacing: 'sm:tracking-[0.14px] md:tracking-[0.16px]',
        color: 'text-[#6F7263]',
        cursor: 'cursor-pointer',
        fontColor: 'text-grey-400 checked:text-grey-500',
        flex: 'grow flex-shrink-0 basis-0 md:flex-none',
      },
      icon: {
        position: 'absolute',
        top: 'top-2/4',
        left: 'left-2/4',
        translate: '-translate-y-2/4 -translate-x-2/4',
        pointerEvents: 'pointer-events-none',
        opacity: 'opacity-0 peer-checked:opacity-100',
        transition: 'transition-opacity',
      },
      disabled: {
        opacity: 'opacity-50',
        pointerEvents: 'pointer-events-none',
      },
    },
    colors: {
      green: {
        color: 'text-transparent',
        border: 'checked:border-primary-100',
        before: 'checked:before:bg-primary-100',
      },
    },
  },
};

export default radioButtonTheme;
