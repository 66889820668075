import { ThemeProvider } from '@material-tailwind/react/context/theme';
import { FrontasticProvider } from 'frontastic';
import 'helpers/analytics/segment-helper';
import type { AppContext, AppProps } from 'next/app';
import 'tailwindcss/tailwind.css';
import '../styles/app.css';
import App from 'next/app';
import Script from 'next/script';
import { Environment, KameleoonProvider, createClient } from '@kameleoon/react-sdk';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ConsentScripts from 'components/atoms/scripts/one-trust';
import StackAdaptScript from 'components/atoms/scripts/stack-adapt';
import Loading from 'components/organisms/global/loader';
import { parseLogLevels } from 'helpers/datadog/parse-log-levels';
import { store } from 'redux/store';
import { customTheme } from 'styles/themes/theme';
import { version } from '../version';

const persistedStore = persistStore(store);

const FrontasticStarter = ({ Component, pageProps }: AppProps) => {
  console.info('🚀 ~ _app.tsx:', new Date());
  const data: any = pageProps;

  const client = createClient({
    siteCode: process.env.NEXT_PUBLIC_KAMELEOON_SITECODE,
    configuration: {
      updateInterval: 60,
      environment: process.env.NEXT_PUBLIC_CUSTOM_ENV_VARIABLE as Environment,
    },
  });

  return (
    <FrontasticProvider>
      <ThemeProvider value={customTheme}>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistedStore}>
            <KameleoonProvider client={client}>
              <Component {...pageProps} />
            </KameleoonProvider>
            <StackAdaptScript pageProps={data} store={store} />
            <ConsentScripts />
            <Script id="data-dog" strategy="afterInteractive">
              {`(function(h,o,u,n,d) {
                  h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
                  d=o.createElement(u);d.async=1;d.src=n
                  n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
                })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
                window.DD_RUM.onReady(function() {
                  window.DD_RUM.init({
                    clientToken: '${process.env.NEXT_PUBLIC_DD_TOKEN}',
                    applicationId: '${process.env.NEXT_PUBLIC_DD_APPID}',
                    site: 'datadoghq.com',
                    service: 'cofe-headless-fe',
                    env: '${process.env.NEXT_PUBLIC_CUST_ENV}',
                    version: '${version}',
                    sessionSampleRate: ${process.env.NEXT_PUBLIC_DD_RUM_SAMPLE_RATE || '10'} ,
                    sessionReplaySampleRate: ${process.env.NEXT_PUBLIC_DD_RUM_REPLAY_SAMPLE_RATE || '10'},
                    trackUserInteractions: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_USER_INTERACTIONS || 'true'},
                    trackResources: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_RESOURCES || 'true'},
                    trackLongTasks: ${process.env.NEXT_PUBLIC_DD_RUM_TRACK_LONG_TASKS || 'true'},
                    defaultPrivacyLevel: '${process.env.NEXT_PUBLIC_DD_RUM_PRIVACY_LEVEL || 'mask-user-input'}',
                  });
                })
              `}
            </Script>
            <Script id="data-dog-logs">
              {` (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-logs.js','DD_LOGS')
      window.DD_LOGS.onReady(function() {
          window.DD_LOGS.init({
            clientToken: '${process.env.NEXT_PUBLIC_DD_TOKEN}',
            site: 'datadoghq.com',
            service: 'cofe-headless-fe',
            version: '${version}',
            env: '${process.env.NEXT_PUBLIC_CUST_ENV}',
            forwardErrorsToLogs: ${process.env.NEXT_PUBLIC_DD_LOGS_ENABLED || 'true'},
            sessionSampleRate: ${process.env.NEXT_PUBLIC_DD_LOGS_SAMPLE_RATE || '100'},
            forwardConsoleLogs: ${parseLogLevels(process.env.NEXT_PUBLIC_DD_LOGS_CONSOLE_LOGS_LEVELS) || `'all'`},
          })
        })`}
            </Script>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </FrontasticProvider>
  );
};

FrontasticStarter.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  appProps.pageProps['host'] = appContext.ctx.req.headers.host;
  if (appContext.ctx.res?.statusCode === 500) {
    appContext.ctx.res.writeHead(302, { Location: '/error' });
    appContext.ctx.res.end();
    return;
  }

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/404' });
    appContext.ctx.res.end();
    return;
  }

  return { ...appProps };
};
export default appWithTranslation(FrontasticStarter);
