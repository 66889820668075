import Image from 'next/image';
import { loaderUrl } from 'helpers/constants/loaderUrl';
import { LoaderWrapper } from './styles';

export type DrawerPlacement = 'bottom' | 'right';
export interface ILoaderProps {
  drawerplacement?: DrawerPlacement;
  width?: number;
  height?: number;
  showbackdrop?: boolean;
}

const Loading = ({ width, height, showbackdrop = true, drawerplacement }: ILoaderProps) => {
  return (
    <LoaderWrapper $drawerplacement={drawerplacement} $showbackdrop={showbackdrop} data-testid="Loader-Wrapper">
      <Image
        src={loaderUrl}
        alt="RISE loader"
        width={width ? width : 62.48}
        height={height ? height : 16}
        loader={(options) => options.src}
        data-testid="Loader"
      />
    </LoaderWrapper>
  );
};

export default Loading;
