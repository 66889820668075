const toggleTheme = {
  defaultProps: {
    color: 'green',
    label: '',
    ripple: false,
    className: '',
    disabled: false,
    containerProps: undefined,
    labelProps: undefined,
    circleProps: undefined,
  },
  valid: {
    colors: ['green'],
  },
  styles: {
    base: {
      root: {
        display: 'flex',
        alignItems: 'items-center',
        gap: 'gap-4',
        flexDirection: 'flex-row-reverse',
        width: 'w-[350px] lg:w-[460]',
        height: 'h-[24px]',
      },
      container: {
        position: 'relative',
        display: 'inline-block',
        width: 'w-8',
        height: 'h-4',
        cursor: 'cursor-pointer',
        borderRadius: 'rounded-full',
      },
      input: {
        peer: 'peer',
        appearance: 'appearance-none',
        height: 'h-[24px]',
        width: 'w-[40px]',
        top: '-top-[4px]',
        position: 'absolute',
        background: 'bg-gray-400',
        borderRadius: 'rounded-full',
        cursor: 'cursor-pointer',
        transition: 'transition-colors duration-300',
      },
      circle: {
        bg: 'bg-white',
        width: 'w-5',
        height: 'h-5',
        borderWidth: 'border',
        borderColor: 'border-blue-gray-100',
        borderRadius: 'rounded-full',
        boxShadow: 'shadow-md',
        position: 'absolute',
        top: 'top-2/4',
        left: 'left-[0.15rem]',
        transform: '-translate-y-2/4 peer-checked:translate-x-full',
        transition: 'transition-all duration-300',
        cursor: 'cursor-pointer',
        before: {
          content: "before:content['']",
          display: 'before:block',
          bg: 'before:bg-blue-gray-500',
          width: 'before:w-10',
          height: 'before:h-10',
          borderRadius: 'before:rounded-full',
          position: 'before:absolute',
          top: 'before:top-2/4',
          left: 'before:left-2/4',
          transform: 'before:-translate-y-2/4 before:-translate-x-2/4',
          transition: 'before:transition-opacity',
          opacity: 'before:opacity-0 hover:before:opacity-10',
        },
      },
      ripple: {
        display: 'inline-block',
        top: 'top-2/4',
        left: 'left-2/4',
        transform: '-translate-x-2/4 -translate-y-2/4',
        p: 'p-5',
        borderRadius: 'rounded-full',
      },
      label: {
        display: 'flex',
        width: 'w-[350px]',
        padding: 'p-[20px_0px]',
        color: 'text-gray-700',
        alignItems: '',
        gap: 'gap-4',
        fontWeight: 'font-light',
        userSelect: 'select-none',
        cursor: 'cursor-pointer',
        mt: 'mt-px',
        ml: 'ml-0',
        mb: 'mb-0',
      },
      disabled: {
        opacity: 'opacity-50',
        pointerEvents: 'pointer-events-none',
      },
    },
    colors: {
      green: {
        input: "checked:bg-leaf checked:bg-[url('')]",
        circle: 'peer-checked:border-leaf peer-checked:-left-[0.15rem]',
        before: 'peer-checked:before:bg-leaf',
        bg: 'checked:focus:bg-leaf checked:hover:bg-leaf',
      },
    },
  },
};

export default toggleTheme;
